import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import MarkdownContent from "../components/markdown";

export const AboutPageTemplate = ({
  description,
}) => <div>

    <div className="container py-5">
        <MarkdownContent content={description}></MarkdownContent>
    </div>
</div>;

AboutPageTemplate.propTypes = {

};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <AboutPageTemplate
        description={frontmatter.description}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
          description
      }
    }
  }
`;
