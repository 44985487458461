import React from "react";
import PropTypes from "prop-types";
import showdown from "showdown";

const converter = new showdown.Converter({ openLinksInNewWindow: true });

const MarkdownContent = ({ content, className }) => (
  <div
    className={className + " markdown-block"}
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
  />
);

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
};

export default MarkdownContent;
